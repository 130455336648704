import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
//import "bulma/css/bulma.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
//require("../../backend/middleware/addProxyMiddleware");

axios.defaults.withCredentials = true;

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
        
    <App />
      
  </React.StrictMode>
  //,document.getElementById("root")
);
document.body.className = "authentication-bg-pattern";
