import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "./logo-back.png";
import { useEffect } from "react";
import Modal from "react-modal";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [userdata, setUserData] = useState("");
  const navigate = useNavigate();
  const [lastName, setLastName] = useState("");
  const [acctId, setAccId] = useState("");
  const [smsno, setSmsno] = useState({ msisdn: "" });
  const [resvalidate, setResvalidate] = useState("");
  const [pin, setPin] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
    console.log(smsno);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // const [data, setData] = useState({});

  /*   const Check = async (e) => {
    e.preventDefault();
    try {
      return fetch("https://luzvimincatv.com/api/balance/subscriber", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Check).then((data) => {
          data.json();
          console.log({ data });
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };
 */

  const Otpsend = async () => {
    // e.preventDefault();
    try {
      const paramsObj = {
        app_key: "wcMKU87JG3yN2ivy",
        app_secret: "bUPI1GD1ymXtYUNt",
        smsno,
        content: "test ",
        shortcode_mask: "LUZVIMIN",
      };
      const object = new URLSearchParams(paramsObj);
      // object.append("app_key", "wcMKU87JG3yN2ivy");
      // object.append("app_secret", "bUPI1GD1ymXtYUNt");
      // object.append("msisdn", smsno);
      // object.append("content", "OTP pin is " + { pin });
      // object.append("shortcode_mask", "LUZVIMIN");
      //object.append("pin","145312")
      //object.append("is_intl", "false");
      const response = await fetch("https://api.m360.com.ph/v3/api/pin/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: object,
        mode: "no-cors",
      });
      /* const res = await axios
        .post("https://api.m360.com.ph/v3/api/broadcast", {
          app_key: "wcMKU87JG3yN2ivy",
          app_secret: "bUPI1GD1ymXtYUNt",
          msisdn: "639566389760",
          content: "Hello, this is a test broadcast.",
          shortcode_mask: "LUZVIMIN",
          is_intl: false,
        })
        .then((res) => {
          console.log(res);
        }); */
      //if (!response.ok) throw Error(response.statusText);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const Validateact = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        //"https://catfact.ninja/fact",
        "https://reliancecatv.com/api/iptv-account/validate?account_no=" +
          acctId.toString() +
          "&name=" +
          lastName.toString(),
        {
          //mode: "no-cors",
          method: "GET",
          headers: {
            // Accept: "text/html,application/xhtml+xml",
            "Sec-Fetch-Mode": "navigate",
            // "Sec-Fetch-Dest": "document",
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
          // params: { account_no: acctId },
        }
      );

      console.log(acctId);

      if (!response.ok) {
        console.log("!!!!!");
        throw new Error(`HTTP error! status: s${response.status}`);
      }
      const data = await response.json();

      // enter you logic when the fetch is successful
      //example: show success modal, clear form, route to another page etc.
      console.log(data);
      setPin(Math.floor(Math.random() * 10000000 + 1));
      console.log(data.account.sms_no);
      console.log(pin);
      setSmsno(`msisdn : ${data.account.sms_no} `);
      console.log("ppppp");
      console.log(smsno);
      //Otpsend.paramsObj.msisdn = data.account.sms_no;
      //console.log("sending sms to " + Otpsend.paramsObj.msisdn);
      console.log("sending OPT");
      handleOpenModal("This is some content");
      //  setPin(Math.floor(Math.random() * 1000000 + 1));

      // sendOTP({ msisdn: `${smsno}`, pin });
      if (data.name === true) {
        setResvalidate("PLEASE INPUT OTP");
        setSmsno({ msisdn: `${data.account.sms_no}` });
        console.log(data.account.sms_no);
        console.log(`sms to  ${data.account.sms_no}`);
        console.log(data.account.name);
        console.log(data.account.password);
        document.getElementById("username").value = data.account.username;
      } else {
        setResvalidate(data.message);
      }
    } catch (error) {
      // enter your logic for when there is an error,
      // example: open a modal with error message.
      console.log(error);
    }
  };
  const Check = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        "https://reliancecatv.com/api/iptv-account/validate",
        {
          //mode: "no-cors",
          params: {
            //name: lastName,
            account_no: acctId,
            api_key: "EjNMQ9sNPvTxw3GrxU2fsLKD",
          },
          headers: {
            "Sec-Fetch-Mode": "navigate",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const Register = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:5000/users", {
        name: name,
        email: email,
        password: password,
        confPassword: confPassword,
      });
      navigate("/");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };
  return (
    <section className="authentication-bg ">
      <div className="account-pages mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="main">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <span>
                      <img src={logo} width="200px" alt="tvkt" />
                    </span>
                    <h2 className="auth-title">Sign up</h2>
                  </div>
                  <div>
                    <form onSubmit={Validateact}>
                      <label>
                        Your Account ID:{" "}
                        <input
                          value={acctId}
                          onChange={(e) => setAccId(e.target.value)}
                        />
                      </label>
                      <label>
                        Last Name:{" "}
                        <input
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </label>
                      <p className="sm text-danger">{resvalidate}</p>    
                      <button className="form-control btn btn-success btn-block">
                        Check account
                      </button>
                    </form>
                  </div>
                  <div>
                    {showModal ? (
                      <Modal
                        isOpen={showModal}
                        onRequestClose={handleCloseModal}
                        style={{
                          overlay: {
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                          },
                          content: {
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "500px",
                            height: "500px",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <h1>Modal Title</h1>
                        <p>Modal content.</p>
                        <button onClick={handleCloseModal}>Close Modal</button>
                      </Modal>
                    ) : null}
                  </div>
                                       
                  <form onSubmit={Register}>
                    {" "}
                                                    
                    <p className="sm">{msg}</p>                                 
                    <div className="input-group-sm small">
                      <label className="fw-bolder">Name :</label>               
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                                                            
                    </div>
                                       
                    <div className="input-group-sm small">
                      <label className="fw-bolder">Username</label>{" "}
                                             
                      <input
                        id="username"
                        type="text"
                        className="form-control"
                        placeholder="username"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                                                  
                    </div>
                        
                    <div className="input-group-sm small">
                      <label className="fw-bolder">Password</label>
                                     
                      <input
                        type="password"
                        className="form-control"
                        placeholder="******"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                           
                    </div>
                                                    
                    <div className="input-group-sm small">
                      <label className="fw-bolder">Confirm Password</label>
                            
                      <input
                        type="password"
                        className="form-control"
                        placeholder="******"
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                      />
                    </div>
                    <div className="input-group-sm small">
                      <label>
                        <p>
                          Clicking register will agree to the terms and
                          conditions.
                        </p>
                      </label>
                      <button className="form-control btn btn-success btn-block">
                        Register
                      </button>
                                                      
                    </div>
                                                
                  </form>
                </div>
                                        
              </div>
            </div>{" "}
                                         
          </div>
        </div>
      </div>
    </section>
  );
};
export default Register;
