import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import dotenv from "dotenv";

dotenv.config();
function Text() {
  const [smsno, setSmsno] = useState("");
  const [smscontent, setSmscontent] = useState("");
  const formRef = useRef();

  const Otpsend = async (e) => {
    e.preventDefault();
    console.log(smsno);
    try {
      const paramsObj = {
        app_secret: "bUPI1GD1ymXtYUNt",
        msisdn: smsno,
        content: smscontent,
        shortcode_mask: "LUZVIMIN",
      };
      const object = new URLSearchParams(paramsObj);
      const response = await fetch(
        //"https://api.m360.com.ph/v3/api/broadcast",
        "http://localhost:5000/api/",
        {
          app_key: process.env.REACT_APP_M360_KEY,
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: object,
          mode: "no-cors",
        }
      );

      //handleOpenModal("This is some content");

      if (response.ok) {
        alert("send complete!");
      }
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  //const handleSubmit = async (e) =>

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    const handleClick = (event) => {
      event.preventDefault();
    };

    formRef.current.addEventListener("keydown", handleKeyDown);
    formRef.current
      .querySelector('[type="submit"]')
      .addEventListener("click", handleClick);

    return () => {
      formRef.current.removeEventListener("keydown", handleKeyDown);
      formRef.current
        .querySelector('[type="submit"]')
        .removeEventListener("click", handleClick);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    //async function handleSubmit() {
    // e.preventDefault();
    const data = {
      app_key: process.env.REACT_APP_M360_KEY,
      app_secret: "bUPI1GD1ymXtYUNt",
      msisdn: smsno,
      content: smscontent,
      shortcode_mask: "LUZVIMIN",
    };

    try {
      const response = axios.post(
        "https://node.luzvimincatv.com/api/",
        data,

        {
          withCredentials: false,
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="row justify-content-center col-md-8 col-lg-6 col-xl-5 card">
      <form ref={formRef}>
        <div className="form-outline">
          <label htmlFor="smsno">mobile:</label>
          

          <input
            inputMode="numeric"
            pattern="(?=\d).{11}"
            title="must contain 11 digit"
            id="smsno"
            value={smsno}
            onChange={(e) => setSmsno(e.target.value)}
          ></input>
        </div>

        <div className="form-outline">
          <label htmlFor="smscontent">message:</label>

          <textarea
            className="form-control"
            rows="4"
            cols="50"
            id="smscontent"
            value={smscontent}
            onChange={(e) => setSmscontent(e.target.value)}
          ></textarea>
        </div>
        <div className="form-outline">
          <button
            type="submit"
            onClick={handleSubmit}
            className="form-control btn btn-success btn-block"
          >
            send message
          </button>
        </div>
        <br />
        <br />
        <br />
      </form>
    </div>
  );
}

export default Text;
