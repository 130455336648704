import React from "react";
//import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import Text from "./components/Text";
import Itvreg from "./components/Itvreg";
//import Test from "./components/Tes";
import Sendsms from "./components/Sendsms";
import "./App.css";
function App() {
  return (
    <Router>
            
      <Routes>
           <Route path="/" element={<Itvreg />}></Route> 
         <Route path="/login" element={<Login />}></Route> 
        <Route path="/text" element={<Text />}></Route>          
        <Route path="/sendsms" element={<Sendsms />}></Route>                   
        <Route path="/register" element={<Register />}></Route>
        <Route
          path="/itvreg"
          element={
            <>
              <Itvreg />
            </>
          }
        ></Route>{" "}
         
        <Route
          path="/dashboard"
          element={
            <>
              <Navbar /> <Dashboard />
            </>
          }
        ></Route>
                                  
      </Routes>
          
    </Router>
  );
}
export default App;
