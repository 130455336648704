import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "./logo-back.png";
//import Modal from 'react-bootstrap/Modal';
import Modal from "react-modal";
import { Tabs, Tab } from "react-bootstrap";
import Swal from "sweetalert2";
function Itvreg() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [userdata, setUserData] = useState("");
  const navigate = useNavigate();
  const [lastName, setLastName] = useState("");
  const [acctId, setAccId] = useState("");
  const [smsno, setSmsno] = useState("");
  const [resvalidate, setResvalidate] = useState("");
  const [regsms, setRegsms] = useState("");
  const [pin, setPin] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const formRef = useRef();
  const [ref_code, setRef_code] = useState("");
  const [key, setKey] = useState("acctDetails");
  const [username, setUsername]=useState("");

  //const modalStyles = { content: { width: '80%', margin: 'auto' }, overlay: { zIndex
  //const result = useState({});

  ////////////
  useEffect(() => {
    document.title = 'ITV Registry';

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    const handleClick = (event) => {
      event.preventDefault();
    };

    formRef.current.addEventListener("keydown", handleKeyDown);
    formRef.current
      .querySelector('[type="submit"]')
      .addEventListener("click", handleClick);

    return () => {
      formRef.current.removeEventListener("keydown", handleKeyDown);
      formRef.current
        .querySelector('[type="submit"]')
        .removeEventListener("click", handleClick);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let obj;
    const params = {
      account_no: acctId,
      name: lastName,
    };

    try {
      const response = await axios.get(
        "https://manage.luzvimincatv.com/api/iptv-account/validate",
        {
          params,
          headers: {
            // "Sec-Fetch-Mode": "navigate",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
          withCredentials: false,
        }
      );
      const data = response.data;
      obj = await data.account.sms_no;
      setUsername(`${data.account.account_no}`);
      //setSmsno(await data.account.sms_no);
      //console.log(smsno);
      //alert(`${obj}`);

      handleOpenModal("PLEASE ENTER YOUR REGISTERED MOBILE NUMBER");
      setRegsms(obj);
    } catch (error) {
	console.log(error);
	
	Swal.fire({
 	 icon: "error",
  	 title: "Oops...",
         text:  "Account not found",
         footer: '<a href="#">Why do I have this issue?</a>'
	});
      console.error(error);
    }
  };
  // send OTP to client
  const sendotp = async (event) => {
    event.preventDefault();
    const formVerify = document.querySelector("#form-verify");
    formVerify.disabled = true;

    let ref;
    if (smsno != regsms) {
      return alert("Please enter the registered mobile number!");
    }
    //let msg;
    const data = {
      app_key: "wcMKU87JG3yN2ivy",
      app_secret: "bUPI1GD1ymXtYUNt",
      msisdn: smsno,
      content: "",
      shortcode_mask: "LUZVIMIN",
    };

    try {
      const m360 = axios.post(
        "https://node.luzvimincatv.com/otp/send",
        data,

        {  headers: {
            // "Sec-Fetch-Mode": "navigate",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
          withCredentials: false,
        }
      );
      const resulta = await m360;
      console.log(resulta);
      //setRef_code(res.ref_code);
      //  console.log(response.data);
      /*  if (response.status == 400) {
        msg = "bad request";
      } */
      ref = await resulta.data.ref_code;
      console.log(`the referrence code is ${ref}`);
      return setRef_code(resulta.data.ref_code);
    } catch (error) {
      console.error(error);
    }
  };
  ///verify the PIN to M360
  const verifyPin = async (event) => {
    event.preventDefault();
    // let data = new FormData();
    console.log(`please enter the OTP with ref ${ref_code}`);
    //let msg;
    const data = {
      app_key: "wcMKU87JG3yN2ivy",
      app_secret: "bUPI1GD1ymXtYUNt",
      //  app_key: "wcMKU87JG3yN2ivy",
      // app_secret: "bUPI1GD1ymXtYUNt",
      ref_code: ref_code,
      pin: pin,
    };

    try {
      const response = axios.post("https://node.luzvimincatv.com/verify", data, {
        withCredentials: false,
      });
      const res = await response;
      console.log(res);
      setKey("iptvCredentials");
      setShowModal(false);
    } catch (error) {
      setMsg("Incorrect PIN");

      console.error(error);
    }
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
    console.log(smsno);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
   const Register = async (e) => {
	e.preventDefault();
	
	try {
	 const response =  await axios.post("https://node.luzvimincatv.com/users", {
	   username: username,
	   password: password,
	   confPassword: confPassword,
	});

	   if (response.data.msg==="Registration Successful"){
	   Swal.fire({
		title: "success!",
		text:  "Download APP",
		icon: "success",
		confirmButtonText: "GO",
		    }).then (() => {window.location.href = ("http://10.59.49.11/apk");
	   });
	   }
	}
	catch (error){
	    if(error.response){
		setMsg(error.respose.data.msg);
		}
	}

}
  return (
    <section className="authentication-bg ">
      <div className="account-pages mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="main">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <span>
                      <img src={logo} width="200px" alt="tvkt" />
                    </span>
                    <h2 className="auth-title">Sign up</h2>
                  </div>
                  <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="acctDetails" title="Account Details">
                      <div>
                        <form ref={formRef} id="form-verify">
                          <div>
                            <label className="fw-bolder">Account ID: </label>
                            <input
                              id="form-verify"
                              className="form-control"
                              value={acctId}
                              type="numeric"
                              required
                              onChange={(e) => setAccId(e.target.value)}
                            />
                          </div>
                          <br />
                          <div>
                            <label className="fw-bolder">
                              Last Name:{" "}
                              <input
                                id="form-verify"
                                className="form-control"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </label>
                          </div>
                          <p className="sm text-danger">{resvalidate}</p>    
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="form-control btn btn-success btn-block"
                          >
                            Check account
                          </button>
                        </form>
                      </div>
                    </Tab>
                    <Tab
                      title="IPTV credentials"
                      eventKey="iptvCredentials"
                      disabled
                    >
                                           
                      <form onSubmit={Register}>
                        {" "}
                                                        
                        <p className="sm">{msg}</p>{" "}
                                                        
                        <div className="input-group-sm small">
                          <label className="fw-bolder">UserID :</label>
                                         
                          <input
                            type="text"
                            className="form-control"
                            placeholder="username"
                            value={username}
                            disabled="true"
                            onChange={(e) => setName(e.target.value)}
                          />
			  <br />
                                                                
                        </div>
                                       
                        <div className="input-group-sm small">
                          <label className="fw-bolder">Password</label>
                                         
                          <input
                            type="password"
                            className="form-control"
                            placeholder="******"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                               
                        </div>
                                                        
                        <div className="input-group-sm small">
                          <label className="fw-bolder">Confirm Password</label>
                                
                          <input
                            type="password"
                            className="form-control"
                            placeholder="******"
                            value={confPassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                          />
			  <br />
                        </div>
                        <div className="input-group-sm small">
                          <label>
                            <p>
                              Clicking register will agree to the terms and
                              conditions.
                            </p>
                          </label>
                          <button className="form-control btn btn-success btn-block">
                            Register
                          </button>
                                                          
                        </div>
                                                    
                      </form>
                    </Tab>
                  </Tabs>
                </div>
                                        
              </div>
            </div>{" "}
                                         
          </div>
        </div>
      </div>
      <div>
        {showModal ? (
          <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                height: "auto",
                backgroundColor: "white",
              },
            }}
          >
            <h2>ACCOUNT VERIFICATION </h2>
            <p>Please enter your registered mobile number:</p>
            <form>
              {" "}
              <input
		className = "form-control text-center"
                pattern="(?=\d).{11}"
                title="must contain 11 digit"
                onChange={(e) => setSmsno(e.target.value)}
              ></input>
	       <bR />
              <button
                className="form-control btn btn-success btn-block"
                onClick={sendotp}
              >
                SEND OTP
              </button>
              <br /> <br />
		
              <input
		className = "form-control text-center"
                pattern="(?=\d).{6}"
                title="must contain 6 digit"
                onChange={(e) => setPin(e.target.value)}
              ></input>
	       
              <p className="sm">{msg}</p>
              <button
                className="form-control btn btn-success btn-block"
                onClick={verifyPin}
              >
                VERIFY
              </button>
            </form>
          </Modal>
        ) : null}
      </div>
    </section>
  );
}
export default Itvreg;
