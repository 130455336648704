import React, { useState } from "react";
import Modal from "react-modal";

function Text() {
  const [smsno, setSmsno] = useState("");
  const [otp, setOtp] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
    console.log(smsno);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const Verify = async (e) => {
    e.preventDefault();
    console.log(smsno);
    try {
      const paramsObj = {
        app_key: "wcMKU87JG3yN2ivy",
        app_secret: "bUPI1GD1ymXtYUNt",
        msisdn: smsno,
        content: "test ",
        shortcode_mask: "LUZVIMIN",
      };
      const object = new URLSearchParams(paramsObj);
      const response = await fetch("https://api.m360.com.ph/v3/api/pin/send", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: object,
        mode: "no-cors",
      });
      //handleOpenModal("This is some content");
    } catch (error) {
      console.log(error);
    }
  };

  const Otpsend = async (e) => {
    e.preventDefault();
    console.log(smsno);
    try {
      const paramsObj = {
        app_key: "wcMKU87JG3yN2ivy",
        app_secret: "bUPI1GD1ymXtYUNt",
        msisdn: smsno,
        content:
          "Your One-Time-Pin is #{PIN} and valid for  #{VALIDITY} minutes. Do not share with others. #{REFCODE}",
        limit: 3,
        shortcode_mask: "LUZVIMIN",
      };
      const object = new URLSearchParams(paramsObj);
      const response = await fetch("https://api.m360.com.ph/v3/api/pin/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: object,
        mode: "no-cors",
      });

      //handleOpenModal("This is some content");

      handleOpenModal();
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="row justify-content-center col-md-8 col-lg-6 col-xl-5">
      <form onSubmit={Otpsend}>
        <label htmlFor="smsno">mobile:</label>
        <input
          type="number"
          id="smsno"
          value={smsno}
          onChange={(e) => setSmsno(e.target.value)}
        ></input>

        <button className="form-control btn btn-success btn-block">
          send message
        </button>
        <br />
        <br />
        <br />
      </form>

      <div>
        {showModal ? (
          <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "500px",
                height: "500px",
                backgroundColor: "white",
              },
            }}
          >
            <h1>Modal Title</h1>
            <div>
              <form onSubmit={Verify}>
                <label>Please input OTP</label>
                <input
                  type="number"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <button className="form-control btn btn-success btn-block">
                  VERIFY
                </button>
              </form>
            </div>
            <button onClick={handleCloseModal}>Close Modal</button>
          </Modal>
        ) : null}
      </div>
    </div>
  );
}

export default Text;
